@media only screen and (max-width: 480px) {
    .home {
        height: 862px;
        max-height: calc(100vh - 98px);
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0px 32px 0px 32px;
    }

    .home_login_wrapper {
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text {
        position: relative;
        font-style: italic;
        font-weight: 700;
        font-size: 113.238px;
        line-height: 86%;
        letter-spacing: -0.055em;
        color: #EFEFEF;
        padding-top: 181px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text img {
        width: 82.92px;
        height: 70.05px;
        transform: translate(35px, 12px);
    }

    .home .login_form {
        width: 433px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .home .login_form_header {
        padding: 22.53px 24px 21.98px 28px;
    }

    .home .login_form_header img {
        width: 141px;
        height: 37.5px;
    }

    .home .login_form_header span {
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #8F9098;
    }

    .home .login_form_content {
        height: 614px;
        padding: 28px 39px 67px 28px;
    }

    .home .login_form_title {
        margin-bottom: 28px;
        margin-left: 1px;
        transform: translateY(2px);
    }

    .home .login_form_remember_me {
        margin-top: 24px;
        margin-bottom: 23px;
    }

    .home .form_field {
        padding-right: 3px;
    }

    .home .login_button {
        background: #FF5F00;
    }

    .home .login_form_forgot_password a {
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        color: #3A7EF7;
    }

    .home_woman_img {
        position: absolute;
        bottom: 0px;
        height: 100%;
        left: 326px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .home {
        height: 59.861vw;
        max-height: calc(100vh - 6.805vw);
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0px 2.222vw 0px 2.222vw;
    }

    .home_login_wrapper {
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text {
        position: relative;
        font-style: italic;
        font-weight: 700;
        font-size: 7.863vw;
        line-height: 86%;
        letter-spacing: -0.055em;
        color: #EFEFEF;
        padding-top: 12.569vw;
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text img {
        width: 5.758vw;
        height: 4.864vw;
        transform: translate(2.43vw, 0.833vw);
    }

    .home .login_form {
        width: 30.069vw;
        border-top-left-radius: 1.388vw;
        border-top-right-radius: 1.388vw;
    }

    .home .login_form_header {
        padding: 1.564vw 1.666vw 1.526vw 1.944vw;
    }

    .home .login_form_header img {
        width: 9.791vw;
        height: 2.604vw;
    }

    .home .login_form_header span {
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #8F9098;
    }

    .home .login_form_content {
        height: 42.638vw;
        padding: 1.944vw 2.708vw 4.652vw 1.944vw;
    }

    .home .login_form_title {
        margin-bottom: 1.944vw;
        margin-left: 1px;
        transform: translateY(0.138vw);
    }

    .home .login_form_remember_me {
        margin-top: 1.666vw;
        margin-bottom: 1.597vw;
    }

    .home .form_field {
        padding-right: 0.208vw;
    }

    .home .login_button {
        background: #FF5F00;
    }

    .home .login_form_forgot_password a {
        font-weight: 500;
        font-size: 0.902vw;
        line-height: 150%;
        text-align: center;
        color: #3A7EF7;
    }

    .home_woman_img {
        position: absolute;
        bottom: 0px;
        height: 100%;
        left: 22.638vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .home {
        height: 59.861vw;
        max-height: calc(100vh - 6.805vw);
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0px 2.222vw 0px 2.222vw;
    }

    .home_login_wrapper {
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text {
        position: relative;
        font-style: italic;
        font-weight: 700;
        font-size: 7.863vw;
        line-height: 86%;
        letter-spacing: -0.055em;
        color: #EFEFEF;
        padding-top: 12.569vw;
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text img {
        width: 5.758vw;
        height: 4.864vw;
        transform: translate(2.43vw, 0.833vw);
    }

    .home .login_form {
        width: 30.069vw;
        border-top-left-radius: 1.388vw;
        border-top-right-radius: 1.388vw;
    }

    .home .login_form_header {
        padding: 1.564vw 1.666vw 1.526vw 1.944vw;
    }

    .home .login_form_header img {
        width: 9.791vw;
        height: 2.604vw;
    }

    .home .login_form_header span {
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #8F9098;
    }

    .home .login_form_content {
        height: 42.638vw;
        padding: 1.944vw 2.708vw 4.652vw 1.944vw;
    }

    .home .login_form_title {
        margin-bottom: 1.944vw;
        margin-left: 1px;
        transform: translateY(0.138vw);
    }

    .home .login_form_remember_me {
        margin-top: 1.666vw;
        margin-bottom: 1.597vw;
    }

    .home .form_field {
        padding-right: 0.208vw;
    }

    .home .login_button {
        background: #FF5F00;
    }

    .home .login_form_forgot_password a {
        font-weight: 500;
        font-size: 0.902vw;
        line-height: 150%;
        text-align: center;
        color: #3A7EF7;
    }

    .home_woman_img {
        position: absolute;
        bottom: 0px;
        height: 100%;
        left: 22.638vw;
    }
}

@media only screen and (min-width: 1441px) {
    .home {
        height: 862px;
        max-height: calc(100vh - 98px);
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0px 32px 0px 32px;
    }

    .home_login_wrapper {
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text {
        position: relative;
        font-style: italic;
        font-weight: 700;
        font-size: 113.238px;
        line-height: 86%;
        letter-spacing: -0.055em;
        color: #EFEFEF;
        padding-top: 181px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .home_text img {
        width: 82.92px;
        height: 70.05px;
        transform: translate(35px, 12px);
    }

    .home .login_form {
        width: 433px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .home .login_form_header {
        padding: 22.53px 24px 21.98px 28px;
    }

    .home .login_form_header img {
        width: 141px;
        height: 37.5px;
    }

    .home .login_form_header span {
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #8F9098;
    }

    .home .login_form_content {
        height: 614px;
        padding: 28px 39px 67px 28px;
    }

    .home .login_form_title {
        margin-bottom: 28px;
        margin-left: 1px;
        transform: translateY(2px);
    }

    .home .login_form_remember_me {
        margin-top: 24px;
        margin-bottom: 23px;
    }

    .home .form_field {
        padding-right: 3px;
    }

    .home .login_button {
        background: #FF5F00;
    }

    .home .login_form_forgot_password a {
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        color: #3A7EF7;
    }

    .home_woman_img {
        position: absolute;
        bottom: 0px;
        height: 100%;
        left: 326px;
    }
}