* {
  margin: 0px;
  padding: 0px;
  font-family: 'Circular Std', 'Azo Sans', sans-serif;
  text-decoration: none
}

@import url('./assets/css/grids.css');
@import url('./assets/css/components.css');
@import url('./assets/css/main_layout.css');
@import url('./assets/css/dashboard_layout.css');
@import url('./assets/css/home.css');
@import url('./assets/css/login.css');
@import url('./assets/css/signup.css');
@import url('./assets/css/transactions.css');
@import url('./assets/css/qr_codes.css');
@import url('./assets/css/create_qr_code.css');
@import url('./assets/css/account.css');