@media only screen and (max-width: 480px) {
    .signup {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #8F9098;
    }

    .signup_wrapper {
        grid-column: 5 / span 4;
        grid-row: 1;
    }

    .signup_form {
        background: #191D2C;
        border-radius: 24px;
        overflow: hidden;
    }

    .signup_form_header {
        padding: 35px 31px 24.45px 31px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .signup_form_header img {
        width: 160px;
        height: 42.55px;
    }

    .signup_form_header span {
        font-weight: 400;
        font-size: 28px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .signup_form_content {
        height: 595px;
        overflow-y: auto;
        border-radius: 24px;
        background-color: #FFFFFF;
        padding: 36px 32px;
    }

    .signup_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 10px;
    }

    .signup_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 20px;
    }

    .signup_form_flash_trader {
        margin-top: 12px;
    }

    .signup_form_flash_trader_wrapper {
        margin-top: 14px;
        margin-bottom: 9px;
    }

    .signup_form_flash_trader_checkbox img {
        width: 24px;
        height: 24px;
    }

    .signup_form_flash_trader label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .signup_form_flash_trader label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #191D2C;
    }

    .signup_button {
        margin-bottom: 21px;
        margin-top: 35px;
    }

    .signup_form_forgot_password {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
        text-align: center;
    }

    .signup_form_forgot_password a {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .signup_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;
        margin-top: 32px;
    }

    .signup_footer span {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .signup_footer img {
        width: 76px;
        height: 28.75px;
    }

    .signup_form .form_field {
        margin-bottom: 8px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .signup {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #8F9098;
    }

    .signup_wrapper {
        grid-column: 3 / span 4;
        grid-row: 1;
    }

    .signup_form {
        background: #191D2C;
        border-radius: 1.666vw;
        overflow: hidden;
    }

    .signup_form_header {
        padding: 2.43vw 2.152vw 1.697vw 2.152vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .signup_form_header img {
        width: 11.111vw;
        height: 2.954vw;
    }

    .signup_form_header span {
        font-weight: 400;
        font-size: 1.944vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .signup_form_content {
        height: 41.319vw;
        overflow-y: auto;
        border-radius: 1.666vw;
        background-color: #FFFFFF;
        padding: 2.5vw 2.222vw;
    }

    .signup_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 0.694vw;
    }

    .signup_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 1.388vw;
    }

    .signup_form_flash_trader {
        margin-top: 0.833vw;
    }

    .signup_form_flash_trader_wrapper {
        margin-top: 0.972vw;
        margin-bottom: 0.625vw;
    }

    .signup_form_flash_trader_checkbox img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .signup_form_flash_trader label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 0.5555vw;
    }

    .signup_form_flash_trader label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        color: #191D2C;
    }

    .signup_button {
        margin-bottom: 1.458vw;
        margin-top: 2.43vw;
    }

    .signup_form_forgot_password {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
        text-align: center;
    }

    .signup_form_forgot_password a {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .signup_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.763vw;
        margin-top: 2.222vw;
    }

    .signup_footer span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .signup_footer img {
        width: 5.277vw;
        height: 1.996vw;
    }

    .signup_form .form_field {
        margin-bottom: 0.555vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .signup {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #8F9098;
    }

    .signup_wrapper {
        grid-column: 5 / span 4;
        grid-row: 1;
    }

    .signup_form {
        background: #191D2C;
        border-radius: 1.666vw;
        overflow: hidden;
    }

    .signup_form_header {
        padding: 2.43vw 2.152vw 1.697vw 2.152vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .signup_form_header img {
        width: 11.111vw;
        height: 2.954vw;
    }

    .signup_form_header span {
        font-weight: 400;
        font-size: 1.944vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .signup_form_content {
        height: 41.319vw;
        overflow-y: auto;
        border-radius: 1.666vw;
        background-color: #FFFFFF;
        padding: 2.5vw 2.222vw;
    }

    .signup_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 0.694vw;
    }

    .signup_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 1.388vw;
    }

    .signup_form_flash_trader {
        margin-top: 0.833vw;
    }

    .signup_form_flash_trader_wrapper {
        margin-top: 0.972vw;
        margin-bottom: 0.625vw;
    }

    .signup_form_flash_trader_checkbox img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .signup_form_flash_trader label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 0.5555vw;
    }

    .signup_form_flash_trader label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        color: #191D2C;
    }

    .signup_button {
        margin-bottom: 1.458vw;
        margin-top: 2.43vw;
    }

    .signup_form_forgot_password {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
        text-align: center;
    }

    .signup_form_forgot_password a {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .signup_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.763vw;
        margin-top: 2.222vw;
    }

    .signup_footer span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .signup_footer img {
        width: 5.277vw;
        height: 1.996vw;
    }

    .signup_form .form_field {
        margin-bottom: 0.555vw;
    }
}

@media only screen and (min-width: 1441px) {
    .signup {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #8F9098;
    }

    .signup_wrapper {
        grid-column: 5 / span 4;
        grid-row: 1;
    }

    .signup_form {
        background: #191D2C;
        border-radius: 24px;
        overflow: hidden;
    }

    .signup_form_header {
        padding: 35px 31px 24.45px 31px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .signup_form_header img {
        width: 160px;
        height: 42.55px;
    }

    .signup_form_header span {
        font-weight: 400;
        font-size: 28px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .signup_form_content {
        height: 595px;
        overflow-y: auto;
        border-radius: 24px;
        background-color: #FFFFFF;
        padding: 36px 32px;
    }

    .signup_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 10px;
    }

    .signup_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 20px;
    }

    .signup_form_flash_trader {
        margin-top: 12px;
    }

    .signup_form_flash_trader_wrapper {
        margin-top: 14px;
        margin-bottom: 9px;
    }

    .signup_form_flash_trader_checkbox img {
        width: 24px;
        height: 24px;
    }

    .signup_form_flash_trader label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .signup_form_flash_trader label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #191D2C;
    }

    .signup_button {
        margin-bottom: 21px;
        margin-top: 35px;
    }

    .signup_form_forgot_password {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
        text-align: center;
    }

    .signup_form_forgot_password a {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .signup_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;
        margin-top: 32px;
    }

    .signup_footer span {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .signup_footer img {
        width: 76px;
        height: 28.75px;
    }

    .signup_form .form_field {
        margin-bottom: 8px;
    }
}