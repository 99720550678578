@media only screen and (max-width: 480px) {
    .button {
        cursor: pointer;
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 9999px;
        padding: 16px;
        border: 0px;
        outline: none;
    }

    .button.button_primary {
        background: linear-gradient(180deg, #FF8B00 0%, #FF5F00 100%);
        color: #FFFFFF;
    }

    .button.button_secondary {
        background: #191B2B;
        color: white;
    }

    .button.button_secondary.button_secondary_outlined {
        background: #FFFFFF;
        border: 2px solid #191B2B;
        color: #191D2C;
    }

    .button img {
        width: 24px;
        height: 24px;
    }

    .button span {
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.2px;
    }

    .form_field {
        width: 100%;
        margin-bottom: 16px;
    }

    .form_field_label {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #322A47;
        margin-bottom: 4px;
    }

    .form_field_input {
        display: flex;
        align-items: center;
        height: 52px;
        background: #F9F9F9;
        border: 1px solid #DCDCDF;
        border-radius: 8px;
        padding: 14px 16px;
    }

    .form_field_input input {
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #322A47;
        width: 100%;
    }

    .form_field_hint {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #60636D;
        margin-top: 2px;
    }

    .notification_container {
        width: 100%;
        position: absolute;
        top: -28px;
        min-height: 53px;
        padding: 14.5px 16px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #0CD656;
        border-radius: 12px;
    }

    .notification_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
    }

    .notification_suffix_icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        flex: none;
    }

    .notification_suffix_icon img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .button {
        cursor: pointer;
        height: 3.888vw;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 9999px;
        padding: 1.111vw;
        border: 0px;
        outline: none;
    }

    .button.button_primary {
        background: linear-gradient(180deg, #FF8B00 0%, #FF5F00 100%);
        color: #FFFFFF;
    }

    .button.button_secondary {
        background: #191B2B;
        color: white;
    }

    .button.button_secondary.button_secondary_outlined {
        background: #FFFFFF;
        border: 2px solid #191B2B;
        color: #191D2C;
    }

    .button img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .button span {
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 120%;
        letter-spacing: -0.2px;
    }

    .form_field {
        width: 100%;
        margin-bottom: 1.111vw;
    }

    .form_field_label {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 150%;
        color: #322A47;
        margin-bottom: 0.277vw;
    }

    .form_field_input {
        display: flex;
        align-items: center;
        height: 3.611vw;
        background: #F9F9F9;
        border: 1px solid #DCDCDF;
        border-radius: 8px;
        padding: 0.972vw 1.111vw;
    }

    .form_field_input input {
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #322A47;
        width: 100%;
    }

    .form_field_hint {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 150%;
        color: #60636D;
        margin-top: 2px;
    }

    .notification_container {
        width: 100%;
        position: absolute;
        top: -1.944vw;
        min-height: 3.68vw;
        padding: 1vw 1.111vw;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #0CD656;
        border-radius: 0.833vw;
    }

    .notification_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #FFFFFF;
    }

    .notification_suffix_icon {
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .notification_suffix_icon img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .button {
        cursor: pointer;
        height: 3.888vw;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 9999px;
        padding: 1.111vw;
        border: 0px;
        outline: none;
    }

    .button.button_primary {
        background: linear-gradient(180deg, #FF8B00 0%, #FF5F00 100%);
        color: #FFFFFF;
    }

    .button.button_secondary {
        background: #191B2B;
        color: white;
    }

    .button.button_secondary.button_secondary_outlined {
        background: #FFFFFF;
        border: 2px solid #191B2B;
        color: #191D2C;
    }

    .button img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .button span {
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 120%;
        letter-spacing: -0.2px;
    }

    .form_field {
        width: 100%;
        margin-bottom: 1.111vw;
    }

    .form_field_label {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 150%;
        color: #322A47;
        margin-bottom: 0.277vw;
    }

    .form_field_input {
        display: flex;
        align-items: center;
        height: 3.611vw;
        background: #F9F9F9;
        border: 1px solid #DCDCDF;
        border-radius: 8px;
        padding: 0.972vw 1.111vw;
    }

    .form_field_input input {
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #322A47;
        width: 100%;
    }

    .form_field_hint {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 150%;
        color: #60636D;
        margin-top: 2px;
    }

    .notification_container {
        width: 100%;
        position: absolute;
        top: -1.944vw;
        min-height: 3.68vw;
        padding: 1vw 1.111vw;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #0CD656;
        border-radius: 0.833vw;
    }

    .notification_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #FFFFFF;
    }

    .notification_suffix_icon {
        cursor: pointer;
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .notification_suffix_icon img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 1441px) {
    .button {
        cursor: pointer;
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 9999px;
        padding: 16px;
        border: 0px;
        outline: none;
    }

    .button.button_primary {
        background: linear-gradient(180deg, #FF8B00 0%, #FF5F00 100%);
        color: #FFFFFF;
    }

    .button.button_secondary {
        background: #191B2B;
        color: white;
    }

    .button.button_secondary.button_secondary_outlined {
        background: #FFFFFF;
        border: 2px solid #191B2B;
        color: #191D2C;
    }

    .button img {
        width: 24px;
        height: 24px;
    }

    .button span {
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.2px;
    }

    .form_field {
        width: 100%;
        margin-bottom: 16px;
    }

    .form_field_label {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #322A47;
        margin-bottom: 4px;
    }

    .form_field_input {
        display: flex;
        align-items: center;
        height: 52px;
        background: #F9F9F9;
        border: 1px solid #DCDCDF;
        border-radius: 8px;
        padding: 14px 16px;
    }

    .form_field_input input {
        border: 0px;
        background-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #322A47;
        width: 100%;
    }

    .form_field_hint {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #60636D;
        margin-top: 2px;
    }

    .notification_container {
        width: 100%;
        position: absolute;
        top: -28px;
        min-height: 53px;
        padding: 14.5px 16px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #0CD656;
        border-radius: 12px;
    }

    .notification_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
    }

    .notification_suffix_icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        flex: none;
    }

    .notification_suffix_icon img {
        width: 100%;
        height: 100%;
    }
}