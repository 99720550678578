* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0px;
}

body {
    font-family: 'Azo Sans Bk';
}

@media only screen and (max-width: 480px) {
    .button_action {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4.222vw 5.667vw;
        background: #FF4600;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF !important;
        /* font-size: 16px; */
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 59.167vw;
        height: 13.889vw;
        font-size:4.4444vw;
        text-decoration:none;
    }

    .btn_center {
        margin: 0px auto;
    }

    .grid {
        display: grid;
        grid-gap: 16px;
        row-gap: 0px;
        padding-left: 16px;
        padding-right: 16px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: [row] auto [row] auto;
    }

    .button_action_two {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 6px 12px;
        background: #055CF5;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 121px;
        height: 36px;
        font-size: 14px;
    }

    .btn-w svg {
        width: 6.666vw;
        height: 6.666vw;
    }

}

@media only screen and (min-width: 481px) and (max-width: 600px) {

    .button_action {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2vw 2.667vw;
        background: #FF4600;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF !important;
        /* font-size: 16px; */
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 27.734vw;
        height: 6.510vw;
        font-size: 1.953vw;
        text-decoration:none;
    }

    .btn_center {
        margin: 0px auto;
    }

    .grid {
        display: grid;
        grid-gap: 16px;
        row-gap: 0px;
        padding-left: 16px;
        padding-right: 16px;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: [row] auto [row] auto [row];
    }

    .button_action_two {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 6px 12px;
        background: #055CF5;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF !important;
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 121px;
        height: 36px;
        font-size: 14px;
    }

    .btn-w svg {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 601px) and (max-width: 840px) {

    .button_action {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 20px;
        background: #FF4600;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF !important;
        /* font-size: 16px; */
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 27.734vw;
        height: 6.510vw;
        font-size: 1.953vw;
        text-decoration:none;
    }

    .btn_center {
        margin: 0px auto;
    }

    .grid {
        display: grid;
        grid-gap: 24px;
        row-gap: 0px;
        padding-left: 24px;
        padding-right: 24px;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: [row] auto [row] auto [row];
    }

    .button_action_two {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 6px 12px;
        background: #055CF5;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 121px;
        height: 36px;
        font-size: 14px;
    }

    .btn-w svg {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .button_action {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1.111vw 1.397vw;
        background: #FF4600;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF !important;
        /* font-size: 16px; */
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 14.792vw;
        height: 3.472vw;
        font-size: 1.111vw;
        text-decoration:none;
    }

    .btn_center {
        margin: 0px auto;
    }

    .grid {
        display: grid;
        grid-gap: 24px;
        row-gap: 0px;
        padding-left: 48px;
        padding-right: 48px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: [row] auto [row] auto [row];
    }

    /* .about-us-carousel-grid {
        display: grid;
        grid-gap: 24px;
        grid-row-gap: 0px;
        row-gap: 0px;
        padding-left: 0px;
        padding-right: 0px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: [row] auto [row] auto [row];
    } */

    .button_action_two {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.511vw 0.897vw;
        background: #055CF5;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 8.403vw;
        height: 2.5vw;
        font-size: 0.768vw;
    }


}

@media only screen and (min-width: 1441px) {

    .button_action {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 20px;
        background: #FF4600;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF !important;
        /* font-size: 16px; */
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 213px;
        height: 50px;
        font-size: 16px;
        text-decoration:none;
    }

    .btn_center {
        margin: 0px auto;
    }

    .grid {
        display: grid;
        grid-gap: 24px;
        row-gap: 0px;
        padding-left: 48px;
        padding-right: 48px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: [row] auto [row] auto [row];
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
    }

    .button_action_two {
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 6px 12px;
        background: #055CF5;
        border-radius: 32px;
        border: 0;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: -0.01em;
        font-weight: bold;
        font-style: normal;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 121px;
        height: 36px;
        font-size: 14px;
    }

    .btn-w svg {
        width: 24px;
        height: 24px;
    }

}