@media only screen and (max-width: 480px) {
    .main_layout {
        background-color: #FF5F00;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .main_layout_header {
        padding-left: 32px;
        padding-right: 32px;
        height: 98px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: 1 / span 4;
        grid-row: 1;
    }

    .main_layout_body {
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    .main_layout_header_left {
        display: flex;
        gap: 60px;
        align-items: center;
    }

    .main_layout_header_logo {
        width: 157.93px;
        height: 42px;
        flex: none;
    }

    .main_layout_header_left_links {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .main_layout_header_left_links a {
        padding: 4px;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        text-decoration: none;
    }

    .main_layout_header_right {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .main_layout_header_right_links {
        display: flex;
        gap: 32px;
    }

    .main_layout_header_right_links a {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_right_links a img {
        width: 18px;
        height: 18px;
    }

    .main_layout_header_shopnow_btn {
        width: 150px;
        height: 56px;
        background-color: #111418;
        border-radius: 9999px;
        padding: 16px 18px 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        outline: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_shopnow_btn img {
        width: 24px;
        height: 24px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .main_layout {
        background-color: #FF5F00;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .main_layout_header {
        padding-left: 2.222vw;
        padding-right: 2.222vw;
        height: 6.805vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: 1 / span 8;
        grid-row: 1;
    }

    .main_layout_body {
        grid-column: 1 / span 8;
        grid-row: 2;
    }

    .main_layout_header_left {
        display: flex;
        gap: 4.166vw;
        align-items: center;
    }

    .main_layout_header_logo {
        width: 10.967vw;
        height: 2.916vw;
        flex: none;
    }

    .main_layout_header_left_links {
        display: flex;
        align-items: center;
        gap: 1.666vw;
    }

    .main_layout_header_left_links a {
        padding: 0.277vw;
        font-weight: 500;
        font-size: 1.25vw;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        text-decoration: none;
    }

    .main_layout_header_right {
        display: flex;
        align-items: center;
        gap: 2.083vw;
    }

    .main_layout_header_right_links {
        display: flex;
        gap: 2.222vw;
    }

    .main_layout_header_right_links a {
        padding-top: 0.555vw;
        padding-bottom: 0.555vw;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_right_links a img {
        width: 1.25vw;
        height: 1.25vw;
    }

    .main_layout_header_shopnow_btn {
        width: 10.416vw;
        height: 3.888vw;
        background-color: #111418;
        border-radius: 9999px;
        padding: 1.111vw 1.25vw 1.111vw 1.666vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        outline: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        display: flex;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_shopnow_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .main_layout {
        background-color: #FF5F00;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .main_layout_header {
        padding-left: 2.222vw;
        padding-right: 2.222vw;
        height: 6.805vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: 1 / span 12;
        grid-row: 1;
    }

    .main_layout_body {
        grid-column: 1 / span 12;
        grid-row: 2;
    }

    .main_layout_header_left {
        display: flex;
        gap: 4.166vw;
        align-items: center;
    }

    .main_layout_header_logo {
        width: 10.967vw;
        height: 2.916vw;
        flex: none;
    }

    .main_layout_header_left_links {
        display: flex;
        align-items: center;
        gap: 1.666vw;
    }

    .main_layout_header_left_links a {
        padding: 0.277vw;
        font-weight: 500;
        font-size: 1.25vw;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        text-decoration: none;
    }

    .main_layout_header_right {
        display: flex;
        align-items: center;
        gap: 2.083vw;
    }

    .main_layout_header_right_links {
        display: flex;
        gap: 2.222vw;
    }

    .main_layout_header_right_links a {
        padding-top: 0.555vw;
        padding-bottom: 0.555vw;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_right_links a img {
        width: 1.25vw;
        height: 1.25vw;
    }

    .main_layout_header_shopnow_btn {
        width: 10.416vw;
        height: 3.888vw;
        background-color: #111418;
        border-radius: 9999px;
        padding: 1.111vw 1.25vw 1.111vw 1.666vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        outline: none;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        display: flex;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_shopnow_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }
}

@media only screen and (min-width: 1441px) {
    .main_layout {
        background-color: #FF5F00;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .main_layout_header {
        padding-left: 32px;
        padding-right: 32px;
        height: 98px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: 1 / span 12;
        grid-row: 1;
    }

    .main_layout_body {
        grid-column: 1 / span 12;
        grid-row: 2;
    }

    .main_layout_header_left {
        display: flex;
        gap: 60px;
        align-items: center;
    }

    .main_layout_header_logo {
        width: 157.93px;
        height: 42px;
        flex: none;
    }

    .main_layout_header_left_links {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .main_layout_header_left_links a {
        padding: 4px;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        text-decoration: none;
    }

    .main_layout_header_right {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .main_layout_header_right_links {
        display: flex;
        gap: 32px;
    }

    .main_layout_header_right_links a {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_right_links a img {
        width: 18px;
        height: 18px;
    }

    .main_layout_header_shopnow_btn {
        width: 150px;
        height: 56px;
        background-color: #111418;
        border-radius: 9999px;
        padding: 16px 18px 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        outline: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .main_layout_header_shopnow_btn img {
        width: 24px;
        height: 24px;
    }
}