@media only screen and (max-width: 480px) {
    .account {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .account_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 45px;
    }

    .account_title {
        font-weight: 700;
        font-size: 44px;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 25px;
        color: #191D2C;
    }

    .account_balance {
        margin-right: 23px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .account_balance_title {
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .account_balance_value {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .account_body {
        padding-left: 26px;
    }

    .account_overview_header {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 19px;
    }

    .account_overview_items {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .account_overview_item {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .account_overview_item_icon {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .account_overview_item_text {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .account_overview_item_text a {
        color: #3A7EF7;
        text-decoration: none;
    }

    .account_information {
        display: flex;
        gap: 24px;
        margin-top: 42px;
    }

    .account_information_business {
        width: 424px;
        height: 494px;
        padding: 31px 32px 77px 32px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .account_information_business_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 30px;
    }

    .account_information_business_field:nth-child(2) {
        margin-bottom: 18px;
    }

    .account_information_business_field:nth-child(3) {
        margin-bottom: 22px;
    }

    .account_information_business_field:nth-child(4) {
        margin-bottom: 100px;
    }

    .account_information_business_label {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #8F9098;
    }

    .account_information_business_value {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
    }

    .account_update_btn {
        width: 193px;
        justify-content: center;
    }

    .account_information_password {
        width: 424px;
        height: 494px;
        padding: 31px 28px 74px 29px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .account_information_password_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 30px;
    }

    .account_information_password_form {
        display: flex;
        flex-direction: column;
        gap: 11px;
    }

    .account_information_form_field {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .account_information_form_field_label {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #191D2C;
    }

    .account_information_form_field_input {
        height: 50px;
        padding: 13px 16px;
        background: #FFFFFF;
        border: 1px solid #CACBCE;
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
        outline: none;
    }

    .account_information_updating {
        margin-top: 31px;
        display: flex;
        gap: 31px;
    }

    .account_information_updating>div {
        width: 367px;
    }

    .account_information_updating_title {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
        margin-bottom: 18px;
    }

    .account_information_updating_form {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin-bottom: 23px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .account {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .account_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3.125vw;
    }

    .account_title {
        font-weight: 700;
        font-size: 3.055vw;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 1.736vw;
        color: #191D2C;
    }

    .account_balance {
        margin-right: 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .account_balance_title {
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .account_balance_value {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .account_body {
        padding-left: 1.805vw;
    }

    .account_overview_header {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 1.319vw;
    }

    .account_overview_items {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .account_overview_item {
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .account_overview_item_icon {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .account_overview_item_text {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .account_overview_item_text a {
        color: #3A7EF7;
        text-decoration: none;
    }

    .account_information {
        display: flex;
        gap: 1.666vw;
        margin-top: 2.916vw;
    }

    .account_information_business {
        width: 29.444vw;
        height: 34.305vw;
        padding: 2.152vw 2.222vw 5.347vw 2.222vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .account_information_business_title {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 2.083vw;
    }

    .account_information_business_field:nth-child(2) {
        margin-bottom: 1.25vw;
    }

    .account_information_business_field:nth-child(3) {
        margin-bottom: 1.527vw;
    }

    .account_information_business_field:nth-child(4) {
        margin-bottom: 6.944vw;
    }

    .account_information_business_label {
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #8F9098;
    }

    .account_information_business_value {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
    }

    .account_update_btn {
        width: 13.402vw;
        justify-content: center;
    }

    .account_information_password {
        width: 29.444vw;
        height: 34.305vw;
        padding: 2.152vw 1.944vw 5.138vw 2.013vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .account_information_password_title {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 2.083vw;
    }

    .account_information_password_form {
        display: flex;
        flex-direction: column;
        gap: 0.763vw;
    }

    .account_information_form_field {
        display: flex;
        flex-direction: column;
        gap: 0.277vw;
    }

    .account_information_form_field_label {
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #191D2C;
    }

    .account_information_form_field_input {
        height: 3.472vw;
        padding: 0.902vw 1.111vw;
        background: #FFFFFF;
        border: 1px solid #CACBCE;
        border-radius: 0.555vw;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
        outline: none;
    }

    .account_information_updating {
        margin-top: 2.152vw;
        display: flex;
        gap: 2.152vw;
    }

    .account_information_updating>div {
        width: 25.468vw;
    }

    .account_information_updating_title {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
        margin-bottom: 1.25vw;
    }

    .account_information_updating_form {
        display: flex;
        flex-direction: column;
        gap: 0.763vw;
        margin-bottom: 1.597vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .account {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .account_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3.125vw;
    }

    .account_title {
        font-weight: 700;
        font-size: 3.055vw;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 1.736vw;
        color: #191D2C;
    }

    .account_balance {
        margin-right: 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .account_balance_title {
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .account_balance_value {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .account_body {
        padding-left: 1.805vw;
    }

    .account_overview_header {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 1.319vw;
    }

    .account_overview_items {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .account_overview_item {
        display: flex;
        align-items: center;
        gap: 0.833vw;
    }

    .account_overview_item_icon {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .account_overview_item_text {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .account_overview_item_text a {
        color: #3A7EF7;
        text-decoration: none;
    }

    .account_information {
        display: flex;
        gap: 1.666vw;
        margin-top: 2.916vw;
    }

    .account_information_business {
        width: 29.444vw;
        height: 34.305vw;
        padding: 2.152vw 2.222vw 5.347vw 2.222vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .account_information_business_title {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 2.083vw;
    }

    .account_information_business_field:nth-child(2) {
        margin-bottom: 1.25vw;
    }

    .account_information_business_field:nth-child(3) {
        margin-bottom: 1.527vw;
    }

    .account_information_business_field:nth-child(4) {
        margin-bottom: 6.944vw;
    }

    .account_information_business_label {
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #8F9098;
    }

    .account_information_business_value {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
    }

    .account_update_btn {
        width: 13.402vw;
        justify-content: center;
    }

    .account_information_password {
        width: 29.444vw;
        height: 34.305vw;
        padding: 2.152vw 1.944vw 5.138vw 2.013vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .account_information_password_title {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 2.083vw;
    }

    .account_information_password_form {
        display: flex;
        flex-direction: column;
        gap: 0.763vw;
    }

    .account_information_form_field {
        display: flex;
        flex-direction: column;
        gap: 0.277vw;
    }

    .account_information_form_field_label {
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #191D2C;
    }

    .account_information_form_field_input {
        height: 3.472vw;
        padding: 0.902vw 1.111vw;
        background: #FFFFFF;
        border: 1px solid #CACBCE;
        border-radius: 0.555vw;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
        outline: none;
    }

    .account_information_updating {
        margin-top: 2.152vw;
        display: flex;
        gap: 2.152vw;
    }

    .account_information_updating>div {
        width: 25.468vw;
    }

    .account_information_updating_title {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
        margin-bottom: 1.25vw;
    }

    .account_information_updating_form {
        display: flex;
        flex-direction: column;
        gap: 0.763vw;
        margin-bottom: 1.597vw;
    }
}

@media only screen and (min-width: 1441px) {
    .account {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .account_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 45px;
    }

    .account_title {
        font-weight: 700;
        font-size: 44px;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 25px;
        color: #191D2C;
    }

    .account_balance {
        margin-right: 23px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .account_balance_title {
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .account_balance_value {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .account_body {
        padding-left: 26px;
    }

    .account_overview_header {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 19px;
    }

    .account_overview_items {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .account_overview_item {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .account_overview_item_icon {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .account_overview_item_text {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .account_overview_item_text a {
        color: #3A7EF7;
        text-decoration: none;
    }

    .account_information {
        display: flex;
        gap: 24px;
        margin-top: 42px;
    }

    .account_information_business {
        width: 424px;
        height: 494px;
        padding: 31px 32px 77px 32px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .account_information_business_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 30px;
    }

    .account_information_business_field:nth-child(2) {
        margin-bottom: 18px;
    }

    .account_information_business_field:nth-child(3) {
        margin-bottom: 22px;
    }

    .account_information_business_field:nth-child(4) {
        margin-bottom: 100px;
    }

    .account_information_business_label {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #8F9098;
    }

    .account_information_business_value {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
    }

    .account_update_btn {
        width: 193px;
        justify-content: center;
    }

    .account_information_password {
        width: 424px;
        height: 494px;
        padding: 31px 28px 74px 29px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .account_information_password_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 30px;
    }

    .account_information_password_form {
        display: flex;
        flex-direction: column;
        gap: 11px;
    }

    .account_information_form_field {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .account_information_form_field_label {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #191D2C;
    }

    .account_information_form_field_input {
        height: 50px;
        padding: 13px 16px;
        background: #FFFFFF;
        border: 1px solid #CACBCE;
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
        outline: none;
    }

    .account_information_updating {
        margin-top: 31px;
        display: flex;
        gap: 31px;
    }

    .account_information_updating>div {
        width: 367px;
    }

    .account_information_updating_title {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
        margin-bottom: 18px;
    }

    .account_information_updating_form {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin-bottom: 23px;
    }
}