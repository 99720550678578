@media only screen and (max-width: 480px) {
    .login {
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8F9098;
    }

    .login_wrapper {
        grid-column: 5 / span 4;
        grid-row: 1;
    }

    .login_form {
        background: #191D2C;
        border-radius: 24px;
        overflow: hidden;
    }

    .login_form_header {
        padding: 35px 31px 24.45px 31px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_form_header img {
        width: 160px;
        height: 42.55px;
    }

    .login_form_header span {
        font-weight: 400;
        font-size: 28px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .login_form_content {
        border-radius: 24px;
        background-color: #FFFFFF;
        padding: 36px 32px;
    }

    .login_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 10px;
    }

    .login_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 20px;
    }

    .login_form_remember_me {
        margin-top: 8px;
        margin-bottom: 23px;
        display: flex;
        gap: 8px;
    }

    .login_form_remember_me_checkbox img {
        width: 24px;
        height: 24px;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .login_form_remember_me label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #191D2C;
    }

    .login_form_remember_me input {
        border: 2px solid #000000;
        border-radius: 6px;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #191D2C;
    }

    .login_button {
        margin-bottom: 11px;
    }

    .login_form_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_form_forgot_password a {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .login_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;
        margin-top: 58.76px;
    }

    .login_footer span {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .login_footer img {
        width: 76px;
        height: 28.75px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .login {
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8F9098;
    }

    .login_wrapper {
        grid-column: 3 / span 4;
        grid-row: 1;
    }

    .login_form {
        background: #191D2C;
        border-radius: 1.666vw;
        overflow: hidden;
    }

    .login_form_header {
        padding: 2.43vw 2.152vw 1.697vw 2.152vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_form_header img {
        width: 11.111vw;
        height: 2.954vw;
    }

    .login_form_header span {
        font-weight: 400;
        font-size: 1.944vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .login_form_content {
        border-radius: 1.666vw;
        background-color: #FFFFFF;
        padding: 2.5vw 2.222vw;
    }

    .login_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 0.694vw;
    }

    .login_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 1.388vw;
    }

    .login_form_remember_me {
        margin-top: 0.555vw;
        margin-bottom: 1.597vw;
        display: flex;
        gap: 0.5555vw;
    }

    .login_form_remember_me_checkbox img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 0.5555vw;
    }

    .login_form_remember_me label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        color: #191D2C;
    }

    .login_form_remember_me input {
        border: 0.138vw solid #000000;
        border-radius: 0.416vw;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        color: #191D2C;
    }

    .login_button {
        margin-bottom: 0.763vw;
    }

    .login_form_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_form_forgot_password a {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .login_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.763vw;
        margin-top: 4.08vw;
    }

    .login_footer span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .login_footer img {
        width: 5.277vw;
        height: 1.996vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .login {
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8F9098;
    }

    .login_wrapper {
        grid-column: 5 / span 4;
        grid-row: 1;
    }

    .login_form {
        background: #191D2C;
        border-radius: 1.666vw;
        overflow: hidden;
    }

    .login_form_header {
        padding: 2.43vw 2.152vw 1.697vw 2.152vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_form_header img {
        width: 11.111vw;
        height: 2.954vw;
    }

    .login_form_header span {
        font-weight: 400;
        font-size: 1.944vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .login_form_content {
        border-radius: 1.666vw;
        background-color: #FFFFFF;
        padding: 2.5vw 2.222vw;
    }

    .login_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 0.694vw;
    }

    .login_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 1.388vw;
    }

    .login_form_remember_me {
        margin-top: 0.555vw;
        margin-bottom: 1.597vw;
        display: flex;
        gap: 0.5555vw;
    }

    .login_form_remember_me_checkbox img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 0.5555vw;
    }

    .login_form_remember_me label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        color: #191D2C;
    }

    .login_form_remember_me input {
        border: 0.138vw solid #000000;
        border-radius: 0.416vw;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        color: #191D2C;
    }

    .login_button {
        margin-bottom: 0.763vw;
    }

    .login_form_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_form_forgot_password a {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .login_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.763vw;
        margin-top: 4.08vw;
    }

    .login_footer span {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .login_footer img {
        width: 5.277vw;
        height: 1.996vw;
    }
}

@media only screen and (min-width: 1441px) {
    .login {
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8F9098;
    }

    .login_wrapper {
        grid-column: 5 / span 4;
        grid-row: 1;
    }

    .login_form {
        background: #191D2C;
        border-radius: 24px;
        overflow: hidden;
    }

    .login_form_header {
        padding: 35px 31px 24.45px 31px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login_form_header img {
        width: 160px;
        height: 42.55px;
    }

    .login_form_header span {
        font-weight: 400;
        font-size: 28px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #CACBCE;
    }

    .login_form_content {
        border-radius: 24px;
        background-color: #FFFFFF;
        padding: 36px 32px;
    }

    .login_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 10px;
    }

    .login_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 20px;
    }

    .login_form_remember_me {
        margin-top: 8px;
        margin-bottom: 23px;
        display: flex;
        gap: 8px;
    }

    .login_form_remember_me_checkbox img {
        width: 24px;
        height: 24px;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .login_form_remember_me label .MuiFormControlLabel-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #191D2C;
    }

    .login_form_remember_me input {
        border: 2px solid #000000;
        border-radius: 6px;
    }

    .login_form_remember_me label {
        margin-left: 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #191D2C;
    }

    .login_button {
        margin-bottom: 11px;
    }

    .login_form_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_form_forgot_password a {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3A7EF7;
    }

    .login_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;
        margin-top: 58.76px;
    }

    .login_footer span {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #EDEEEF;
    }

    .login_footer img {
        width: 76px;
        height: 28.75px;
    }
}