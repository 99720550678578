@media only screen and (max-width: 480px) {
    .qr_codes {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .qr_codes_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .qr_codes_title {
        font-weight: 700;
        font-size: 44px;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 25px;
        color: #191D2C;
    }

    .qr_codes_balance {
        margin-right: 23px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .qr_codes_balance_title {
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .qr_codes_balance_value {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_body {
        padding-left: 26px;
    }

    .qr_code_new_btn {
        width: 193px;
        padding-left: 29px;
        padding-right: 22px;
    }

    .qr_codes_lists {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        width: fit-content;
        margin-top: 35px;
    }

    .qr_codes_item {
        position: relative;
        width: 264px;
        height: 330px;
        padding: 25px 22px 23px 24px;
        background-color: white;
        border-radius: 16px;
    }

    .qr_codes_item_image {
        margin-left: 2px;
        width: 79px;
        height: 79px;
        margin-bottom: 7px;
    }

    .qr_codes_item_title {
        margin-left: 2px;
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_item_size {
        margin-left: 2px;
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #8F9098;
        margin-bottom: 4px;
    }

    .qr_codes_item_amount {
        margin-left: 2px;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.5px;
        color: #191D2C;
        margin-bottom: 17px;
    }

    .qr_codes_item_amount.anyamount {
        color: #3A7EF7;
    }

    .qr_codes_item .download_btn {
        width: calc(100% - 3px);
        height: 48px;
        padding-left: 22px;
        padding-right: 19px;
        margin-bottom: 9px;
    }

    .qr_codes_item .download_btn span {
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #FFFFFF;
    }

    .qr_codes_item .print_btn {
        width: calc(100% - 3px);
        height: 48px;
        padding-left: 18px;
        padding-right: 19px;
    }

    .qr_codes_item .print_btn span {
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
    }

    .qr_codes_item_delete_btn {
        width: 24px;
        height: 24px;
        background-color: transparent;
        outline: none;
        border: 0px;
        position: absolute;
        top: 27px;
        right: 20px;
    }

    .qr_codes_item_delete_btn img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .qr_codes {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .qr_codes_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.666vw;
    }

    .qr_codes_title {
        font-weight: 700;
        font-size: 3.055vw;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 1.736vw;
        color: #191D2C;
    }

    .qr_codes_balance {
        margin-right: 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .qr_codes_balance_title {
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .qr_codes_balance_value {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_body {
        padding-left: 26px;
    }

    .qr_code_new_btn {
        width: 13.402vw;
        padding-left: 2.013vw;
        padding-right: 1.527vw;
    }

    .qr_codes_lists {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.111vw;
        width: fit-content;
        margin-top: 2.43vw;
    }

    .qr_codes_item {
        position: relative;
        width: 18.333vw;
        height: 22.916vw;
        padding: 1.736vw 1.527vw 1.597vw 1.666vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .qr_codes_item_image {
        margin-left: 0.138vw;
        width: 5.486vw;
        height: 5.486vw;
        margin-bottom: 0.486vw;
    }

    .qr_codes_item_title {
        margin-left: 0.138vw;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_item_size {
        margin-left: 0.138vw;
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #8F9098;
        margin-bottom: 0.277vw;
    }

    .qr_codes_item_amount {
        margin-left: 0.138vw;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 120%;
        letter-spacing: -0.5px;
        color: #191D2C;
        margin-bottom: 1.18vw;
    }

    .qr_codes_item_amount.anyamount {
        color: #3A7EF7;
    }

    .qr_codes_item .download_btn {
        width: calc(100% - 0.208vw);
        height: 3.333vw;
        padding-left: 1.527vw;
        padding-right: 1.319vw;
        margin-bottom: 0.625vw;
    }

    .qr_codes_item .download_btn span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #FFFFFF;
    }

    .qr_codes_item .print_btn {
        width: calc(100% - 0.208vw);
        height: 3.333vw;
        padding-left: 1.25vw;
        padding-right: 1.319vw;
    }

    .qr_codes_item .print_btn span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
    }

    .qr_codes_item_delete_btn {
        width: 1.666vw;
        height: 1.666vw;
        background-color: transparent;
        outline: none;
        border: 0px;
        position: absolute;
        top: 1.875vw;
        right: 1.388vw;
    }

    .qr_codes_item_delete_btn img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .qr_codes {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .qr_codes_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.666vw;
    }

    .qr_codes_title {
        font-weight: 700;
        font-size: 3.055vw;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 1.736vw;
        color: #191D2C;
    }

    .qr_codes_balance {
        margin-right: 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .qr_codes_balance_title {
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .qr_codes_balance_value {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_body {
        padding-left: 26px;
    }

    .qr_code_new_btn {
        width: 13.402vw;
        padding-left: 2.013vw;
        padding-right: 1.527vw;
    }

    .qr_codes_lists {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.111vw;
        width: fit-content;
        margin-top: 2.43vw;
    }

    .qr_codes_item {
        position: relative;
        width: 18.333vw;
        height: 22.916vw;
        padding: 1.736vw 1.527vw 1.597vw 1.666vw;
        background-color: white;
        border-radius: 1.111vw;
    }

    .qr_codes_item_image {
        margin-left: 0.138vw;
        width: 5.486vw;
        height: 5.486vw;
        margin-bottom: 0.486vw;
    }

    .qr_codes_item_title {
        margin-left: 0.138vw;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_item_size {
        margin-left: 0.138vw;
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #8F9098;
        margin-bottom: 0.277vw;
    }

    .qr_codes_item_amount {
        margin-left: 0.138vw;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 120%;
        letter-spacing: -0.5px;
        color: #191D2C;
        margin-bottom: 1.18vw;
    }

    .qr_codes_item_amount.anyamount {
        color: #3A7EF7;
    }

    .qr_codes_item .download_btn {
        width: calc(100% - 0.208vw);
        height: 3.333vw;
        padding-left: 1.527vw;
        padding-right: 1.319vw;
        margin-bottom: 0.625vw;
    }

    .qr_codes_item .download_btn span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #FFFFFF;
    }

    .qr_codes_item .print_btn {
        width: calc(100% - 0.208vw);
        height: 3.333vw;
        padding-left: 1.25vw;
        padding-right: 1.319vw;
    }

    .qr_codes_item .print_btn span {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
    }

    .qr_codes_item_delete_btn {
        width: 1.666vw;
        height: 1.666vw;
        background-color: transparent;
        outline: none;
        border: 0px;
        position: absolute;
        top: 1.875vw;
        right: 1.388vw;
    }

    .qr_codes_item_delete_btn img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 1441px) {
    .qr_codes {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .qr_codes_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .qr_codes_title {
        font-weight: 700;
        font-size: 44px;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 25px;
        color: #191D2C;
    }

    .qr_codes_balance {
        margin-right: 23px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .qr_codes_balance_title {
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .qr_codes_balance_value {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_body {
        padding-left: 26px;
    }

    .qr_code_new_btn {
        width: 193px;
        padding-left: 29px;
        padding-right: 22px;
    }

    .qr_codes_lists {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        width: fit-content;
        margin-top: 35px;
    }

    .qr_codes_item {
        position: relative;
        width: 264px;
        height: 330px;
        padding: 25px 22px 23px 24px;
        background-color: white;
        border-radius: 16px;
    }

    .qr_codes_item_image {
        margin-left: 2px;
        width: 79px;
        height: 79px;
        margin-bottom: 7px;
    }

    .qr_codes_item_title {
        margin-left: 2px;
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .qr_codes_item_size {
        margin-left: 2px;
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #8F9098;
        margin-bottom: 4px;
    }

    .qr_codes_item_amount {
        margin-left: 2px;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.5px;
        color: #191D2C;
        margin-bottom: 17px;
    }

    .qr_codes_item_amount.anyamount {
        color: #3A7EF7;
    }

    .qr_codes_item .download_btn {
        width: calc(100% - 3px);
        height: 48px;
        padding-left: 22px;
        padding-right: 19px;
        margin-bottom: 9px;
    }

    .qr_codes_item .download_btn span {
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #FFFFFF;
    }

    .qr_codes_item .print_btn {
        width: calc(100% - 3px);
        height: 48px;
        padding-left: 18px;
        padding-right: 19px;
    }

    .qr_codes_item .print_btn span {
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
    }

    .qr_codes_item_delete_btn {
        width: 24px;
        height: 24px;
        background-color: transparent;
        outline: none;
        border: 0px;
        position: absolute;
        top: 27px;
        right: 20px;
    }

    .qr_codes_item_delete_btn img {
        width: 100%;
        height: 100%;
    }
}