@media only screen and (max-width: 480px) {
    .transactions {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .transactions_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 49px;
    }

    .transactions_title {
        font-weight: 700;
        font-size: 44px;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 25px;
        color: #191D2C;
    }

    .transactions_balance {
        margin-right: 23px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .transactions_balance_title {
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .transactions_balance_value {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_table {
        background: linear-gradient(180deg, #191D2C, transparent);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .transactions_table table {
        min-width: unset !important;
    }

    .transactions_table_header_row {
        background-color: transparent;
    }

    .transactions_table_header_row .MuiTableCell-root {
        padding: 15px 0px 12px 0px;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #FFFFFF;
        border-bottom: 0px !important;
    }

    .transactions_table_header_row .MuiTableCell-root:first-child {
        width: 201px;
        padding-left: 67px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(2) {
        width: 205px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(3) {
        width: 160px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(4) {
        width: 174px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(5) {
        width: 176px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(6) {
        width: 123px;
        text-align: right;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(7) {
        width: 117px;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root {
        padding-top: 21px;
    }

    .transactions_table_body_row .MuiTableCell-root {
        padding: 13px 0px 14px 0px;
        background-color: white !important;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .transactions_table_body_row .MuiTableCell-root:first-child {
        position: relative;
        border-bottom: 0px !important;
        gap: 10px;
        width: 201px;
        padding-left: 25px;
    }

    .transactions_table_username {
        transform: translateY(50%);
    }

    .transactions_table_body_row .MuiTableCell-root:first-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 25px);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        left: 25px;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child {
        position: relative;
        border-bottom: 0px !important;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 25px);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        right: 25px;
    }

    .transactions_table_action {
        width: 24px;
        height: 24px;
    }

    .transactions_table_user_icon {
        width: 32px;
        height: 32px;
        background-color: #191B2B;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        margin-right: 10px;
    }

    .transactions_table_user_icon img {
        width: 24px;
        height: 24px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(2) {
        width: 205px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(3) {
        width: 160px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(4) {
        width: 174px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(5) {
        width: 176px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(6) {
        text-align: right;
        width: 123px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(7) {
        padding-right: 24px;
        text-align: right;
        width: 117px;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:first-child {
        border-top-left-radius: 20px;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:last-child {
        border-top-right-radius: 20px;
    }

    .transactions_table_user_payment_type {
        height: 25px;
        padding: 4px 24px;
        border-radius: 9999px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
        width: fit-content;
    }

    .transactions_table_payment_header {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: auto !important;
    }

    .transactions_table_payment_header .select_payment {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 3px;
    }

    .transactions_table_payment_header .select_payment img {
        width: 24px;
        height: 24px;
    }

    .transactions_table_pagination {
        background-color: white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        justify-content: flex-end;
        padding: 16px 23px;
        gap: 3px;
    }

    .transactions_table_pagination_button {
        background-color: white;
        outline: none;
        width: 32px;
        height: 32px;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000000;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
    }

    .transactions_table_pagination_button img {
        width: 16.29px;
        height: 16.29px;
    }

    .transactions_table_pagination_button.active {
        background-color: #000000;
        color: white;
    }

    .transactions_filter_modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 410px;
        background-color: white;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
    }

    .transactions_filter_modal_header {
        display: flex;
        justify-content: space-between;
        padding: 31px 21px 25px 29px;
    }

    .transactions_filter_modal_header span {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_filter_modal_header img {
        cursor: pointer;
        width: 32px;
        height: 32px;
    }

    .transactions_filter_modal_body {
        padding-left: 29px;
        padding-right: 32px;
        padding-bottom: 31px;
    }

    .transactions_filter_modal_filter_types {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }

    .transactions_filter_modal_filter_type {
        display: flex;
        align-items: center;
        gap: 23px;
        border-bottom: 1px solid #DCDCDF;
        padding-bottom: 18px;
    }

    .transactions_filter_modal_filter_type_checkbox {
        width: 20px;
        height: 20px;
        flex: none;
    }

    .transactions_filter_modal_filter_type_checkbox img {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .transactions {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .transactions_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3.402vw;
    }

    .transactions_title {
        font-weight: 700;
        font-size: 3.055vw;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 1.736vw;
        color: #191D2C;
    }

    .transactions_balance {
        margin-right: 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .transactions_balance_title {
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .transactions_balance_value {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_table {
        background: linear-gradient(180deg, #191D2C, transparent);
        border-top-left-radius: 1.388vw;
        border-top-right-radius: 1.388vw;
    }

    .transactions_table table {
        min-width: unset !important;
    }

    .transactions_table_header_row {
        background-color: transparent;
    }

    .transactions_table_header_row .MuiTableCell-root {
        padding: 1.041vw 0px 0.833vw 0px;
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #FFFFFF;
        border-bottom: 0px !important;
    }

    .transactions_table_header_row .MuiTableCell-root:first-child {
        width: 13.958vw;
        padding-left: 4.652vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(2) {
        width: 14.236vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(3) {
        width: 11.111vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(4) {
        width: 12.083vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(5) {
        width: 12.222vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(6) {
        width: 8.541vw;
        text-align: right;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(7) {
        width: 8.125vw;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root {
        padding-top: 1.458vw;
    }

    .transactions_table_body_row .MuiTableCell-root {
        padding: 0.902vw 0px 0.972vw 0px;
        background-color: white !important;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .transactions_table_body_row .MuiTableCell-root:first-child {
        position: relative;
        border-bottom: 0px !important;
        gap: 0.694vw;
        width: 13.958vw;
        padding-left: 25px;
    }

    .transactions_table_username {
        transform: translateY(50%);
    }

    .transactions_table_body_row .MuiTableCell-root:first-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 1.736vw);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        left: 1.736vw;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child {
        position: relative;
        border-bottom: 0px !important;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 1.736vw);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        right: 1.736vw;
    }

    .transactions_table_action {
        width: 1.666vw;
        height: 1.666vw;
    }

    .transactions_table_user_icon {
        width: 2.222vw;
        height: 2.222vw;
        background-color: #191B2B;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        margin-right: 0.694vw;
    }

    .transactions_table_user_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(2) {
        width: 14.236vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(3) {
        width: 11.111vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(4) {
        width: 12.083vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(5) {
        width: 12.222vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(6) {
        text-align: right;
        width: 8.541vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(7) {
        padding-right: 1.666vw;
        text-align: right;
        width: 8.125vw;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:first-child {
        border-top-left-radius: 1.388vw;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:last-child {
        border-top-right-radius: 1.388vw;
    }

    .transactions_table_user_payment_type {
        height: 1.736vw;
        padding: 0.277vw 1.666vw;
        border-radius: 9999px;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
        width: fit-content;
    }

    .transactions_table_payment_header {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: auto !important;
    }

    .transactions_table_payment_header .select_payment {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.208vw;
    }

    .transactions_table_payment_header .select_payment img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .transactions_table_pagination {
        background-color: white;
        border-bottom-left-radius: 1.388vw;
        border-bottom-right-radius: 1.388vw;
        display: flex;
        justify-content: flex-end;
        padding: 1.111vw 1.597vw;
        gap: 0.208vw;
    }

    .transactions_table_pagination_button {
        background-color: white;
        outline: none;
        width: 2.222vw;
        height: 2.222vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000000;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
    }

    .transactions_table_pagination_button img {
        width: 1.131vw;
        height: 1.131vw;
    }

    .transactions_table_pagination_button.active {
        background-color: #000000;
        color: white;
    }

    .transactions_filter_modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28.473vw;
        background-color: white;
        box-shadow: 0px 0.208vw 0.833vw rgba(0, 0, 0, 0.2);
        border-radius: 1.111vw;
    }

    .transactions_filter_modal_header {
        display: flex;
        justify-content: space-between;
        padding: 2.152vw 1.458vw 1.736vw 2.013vw;
    }

    .transactions_filter_modal_header span {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_filter_modal_header img {
        cursor: pointer;
        width: 2.222vw;
        height: 2.222vw;
    }

    .transactions_filter_modal_body {
        padding-left: 2.013vw;
        padding-right: 2.222vw;
        padding-bottom: 2.152vw;
    }

    .transactions_filter_modal_filter_types {
        display: flex;
        flex-direction: column;
        gap: 1.25vw;
    }

    .transactions_filter_modal_filter_type {
        display: flex;
        align-items: center;
        gap: 1.597vw;
        border-bottom: 1px solid #DCDCDF;
        padding-bottom: 1.25vw;
    }

    .transactions_filter_modal_filter_type_checkbox {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
    }

    .transactions_filter_modal_filter_type_checkbox img {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .transactions {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .transactions_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3.402vw;
    }

    .transactions_title {
        font-weight: 700;
        font-size: 3.055vw;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 1.736vw;
        color: #191D2C;
    }

    .transactions_balance {
        margin-right: 1.597vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .transactions_balance_title {
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .transactions_balance_value {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_table {
        background: linear-gradient(180deg, #191D2C, transparent);
        border-top-left-radius: 1.388vw;
        border-top-right-radius: 1.388vw;
    }

    .transactions_table table {
        min-width: unset !important;
    }

    .transactions_table_header_row {
        background-color: transparent;
    }

    .transactions_table_header_row .MuiTableCell-root {
        padding: 1.041vw 0px 0.833vw 0px;
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #FFFFFF;
        border-bottom: 0px !important;
    }

    .transactions_table_header_row .MuiTableCell-root:first-child {
        width: 13.958vw;
        padding-left: 4.652vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(2) {
        width: 14.236vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(3) {
        width: 11.111vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(4) {
        width: 12.083vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(5) {
        width: 12.222vw;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(6) {
        width: 8.541vw;
        text-align: right;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(7) {
        width: 8.125vw;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root {
        padding-top: 1.458vw;
    }

    .transactions_table_body_row .MuiTableCell-root {
        padding: 0.902vw 0px 0.972vw 0px;
        background-color: white !important;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .transactions_table_body_row .MuiTableCell-root:first-child {
        position: relative;
        border-bottom: 0px !important;
        gap: 0.694vw;
        width: 13.958vw;
        padding-left: 25px;
    }

    .transactions_table_username {
        transform: translateY(50%);
    }

    .transactions_table_body_row .MuiTableCell-root:first-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 1.736vw);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        left: 1.736vw;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child {
        position: relative;
        border-bottom: 0px !important;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 1.736vw);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        right: 1.736vw;
    }

    .transactions_table_action {
        width: 1.666vw;
        height: 1.666vw;
    }

    .transactions_table_user_icon {
        width: 2.222vw;
        height: 2.222vw;
        background-color: #191B2B;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        margin-right: 0.694vw;
    }

    .transactions_table_user_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(2) {
        width: 14.236vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(3) {
        width: 11.111vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(4) {
        width: 12.083vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(5) {
        width: 12.222vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(6) {
        text-align: right;
        width: 8.541vw;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(7) {
        padding-right: 1.666vw;
        text-align: right;
        width: 8.125vw;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:first-child {
        border-top-left-radius: 1.388vw;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:last-child {
        border-top-right-radius: 1.388vw;
    }

    .transactions_table_user_payment_type {
        height: 1.736vw;
        padding: 0.277vw 1.666vw;
        border-radius: 9999px;
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
        width: fit-content;
    }

    .transactions_table_payment_header {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: auto !important;
    }

    .transactions_table_payment_header .select_payment {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.208vw;
    }

    .transactions_table_payment_header .select_payment img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .transactions_table_pagination {
        background-color: white;
        border-bottom-left-radius: 1.388vw;
        border-bottom-right-radius: 1.388vw;
        display: flex;
        justify-content: flex-end;
        padding: 1.111vw 1.597vw;
        gap: 0.208vw;
    }

    .transactions_table_pagination_button {
        background-color: white;
        outline: none;
        width: 2.222vw;
        height: 2.222vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000000;
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
    }

    .transactions_table_pagination_button img {
        width: 1.131vw;
        height: 1.131vw;
    }

    .transactions_table_pagination_button.active {
        background-color: #000000;
        color: white;
    }

    .transactions_filter_modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28.473vw;
        background-color: white;
        box-shadow: 0px 0.208vw 0.833vw rgba(0, 0, 0, 0.2);
        border-radius: 1.111vw;
    }

    .transactions_filter_modal_header {
        display: flex;
        justify-content: space-between;
        padding: 2.152vw 1.458vw 1.736vw 2.013vw;
    }

    .transactions_filter_modal_header span {
        font-weight: 700;
        font-size: 1.666vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_filter_modal_header img {
        cursor: pointer;
        width: 2.222vw;
        height: 2.222vw;
    }

    .transactions_filter_modal_body {
        padding-left: 2.013vw;
        padding-right: 2.222vw;
        padding-bottom: 2.152vw;
    }

    .transactions_filter_modal_filter_types {
        display: flex;
        flex-direction: column;
        gap: 1.25vw;
    }

    .transactions_filter_modal_filter_type {
        display: flex;
        align-items: center;
        gap: 1.597vw;
        border-bottom: 1px solid #DCDCDF;
        padding-bottom: 1.25vw;
    }

    .transactions_filter_modal_filter_type_checkbox {
        width: 1.388vw;
        height: 1.388vw;
        flex: none;
    }

    .transactions_filter_modal_filter_type_checkbox img {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 1441px) {
    .transactions {
        position: relative;
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
    }

    .transactions_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 49px;
    }

    .transactions_title {
        font-weight: 700;
        font-size: 44px;
        line-height: 120%;
        letter-spacing: -1px;
        margin-left: 25px;
        color: #191D2C;
    }

    .transactions_balance {
        margin-right: 23px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .transactions_balance_title {
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #191D2C;
    }

    .transactions_balance_value {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_table {
        background: linear-gradient(180deg, #191D2C, transparent);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .transactions_table table {
        min-width: unset !important;
    }

    .transactions_table_header_row {
        background-color: transparent;
    }

    .transactions_table_header_row .MuiTableCell-root {
        padding: 15px 0px 12px 0px;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #FFFFFF;
        border-bottom: 0px !important;
    }

    .transactions_table_header_row .MuiTableCell-root:first-child {
        width: 201px;
        padding-left: 67px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(2) {
        width: 205px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(3) {
        width: 160px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(4) {
        width: 174px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(5) {
        width: 176px;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(6) {
        width: 123px;
        text-align: right;
    }

    .transactions_table_header_row .MuiTableCell-root:nth-child(7) {
        width: 117px;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root {
        padding-top: 21px;
    }

    .transactions_table_body_row .MuiTableCell-root {
        padding: 13px 0px 14px 0px;
        background-color: white !important;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #191D2C;
    }

    .transactions_table_body_row .MuiTableCell-root:first-child {
        position: relative;
        border-bottom: 0px !important;
        gap: 10px;
        width: 201px;
        padding-left: 25px;
    }

    .transactions_table_username {
        transform: translateY(50%);
    }

    .transactions_table_body_row .MuiTableCell-root:first-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 25px);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        left: 25px;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child {
        position: relative;
        border-bottom: 0px !important;
    }

    .transactions_table_body_row .MuiTableCell-root:last-child::before {
        content: "";
        position: absolute;
        width: calc(100% - 25px);
        border-bottom: 1px solid #e0e0e0;
        z-index: 1;
        bottom: -1px;
        right: 25px;
    }

    .transactions_table_action {
        width: 24px;
        height: 24px;
    }

    .transactions_table_user_icon {
        width: 32px;
        height: 32px;
        background-color: #191B2B;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        margin-right: 10px;
    }

    .transactions_table_user_icon img {
        width: 24px;
        height: 24px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(2) {
        width: 205px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(3) {
        width: 160px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(4) {
        width: 174px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(5) {
        width: 176px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(6) {
        text-align: right;
        width: 123px;
    }

    .transactions_table_body_row .MuiTableCell-root:nth-child(7) {
        padding-right: 24px;
        text-align: right;
        width: 117px;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:first-child {
        border-top-left-radius: 20px;
    }

    .transactions_table_body_row:first-child .MuiTableCell-root:last-child {
        border-top-right-radius: 20px;
    }

    .transactions_table_user_payment_type {
        height: 25px;
        padding: 4px 24px;
        border-radius: 9999px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #191D2C;
        width: fit-content;
    }

    .transactions_table_payment_header {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: auto !important;
    }

    .transactions_table_payment_header .select_payment {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 3px;
    }

    .transactions_table_payment_header .select_payment img {
        width: 24px;
        height: 24px;
    }

    .transactions_table_pagination {
        background-color: white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        justify-content: flex-end;
        padding: 16px 23px;
        gap: 3px;
    }

    .transactions_table_pagination_button {
        background-color: white;
        outline: none;
        width: 32px;
        height: 32px;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000000;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
    }

    .transactions_table_pagination_button img {
        width: 16.29px;
        height: 16.29px;
    }

    .transactions_table_pagination_button.active {
        background-color: #000000;
        color: white;
    }

    .transactions_filter_modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 410px;
        background-color: white;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
    }

    .transactions_filter_modal_header {
        display: flex;
        justify-content: space-between;
        padding: 31px 21px 25px 29px;
    }

    .transactions_filter_modal_header span {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
    }

    .transactions_filter_modal_header img {
        cursor: pointer;
        width: 32px;
        height: 32px;
    }

    .transactions_filter_modal_body {
        padding-left: 29px;
        padding-right: 32px;
        padding-bottom: 31px;
    }

    .transactions_filter_modal_filter_types {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }

    .transactions_filter_modal_filter_type {
        display: flex;
        align-items: center;
        gap: 23px;
        border-bottom: 1px solid #DCDCDF;
        padding-bottom: 18px;
    }

    .transactions_filter_modal_filter_type_checkbox {
        width: 20px;
        height: 20px;
        flex: none;
    }

    .transactions_filter_modal_filter_type_checkbox img {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
}