@media only screen and (max-width: 480px) {
    .create_qr_code {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #83868D;
    }

    .create_qr_code_logo {
        position: absolute;
        top: 37px;
        left: 43px;
        display: flex;
        flex-direction: column;
        gap: 6.82px;
    }

    .create_qr_code_logo img {
        width: 135px;
        height: 35.9px;
    }

    .create_qr_code_logo span {
        font-weight: 400;
        font-size: 23.625px;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #DCDCDF;
    }

    .create_qr_code_form {
        width: 448px;
        height: fit-content;
        border-radius: 24px;
        background-color: white;
        padding: 29px 30px 42px 38px;
    }

    .create_qr_code_form_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_header_icon {
        width: 37px;
        height: 37px;
    }

    .create_qr_code_form_close_btn {
        width: 32px;
        height: 32px;
        border: 0px;
        outline: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .create_qr_code_form_close_btn img {
        width: 32px;
        height: 32px;
    }

    .create_qr_code_form_body {
        padding-left: 3px;
        padding-top: 18px;
        padding-right: 10px;
    }

    .create_qr_code_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 10px;
    }

    .create_qr_code_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 21px;
    }

    .create_qr_code_form_group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .create_qr_code_form_group_label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_group_label span {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #191D2C;
    }

    .create_qr_code_form_group_label_info {
        width: 16px;
        height: 16px;
    }

    .create_qr_code_form_group_control {
        width: 100%;
        display: flex;
        align-items: center;
        background: #F6F6F6;
        border: 1px solid #CACBCE;
        border-radius: 8px;
        height: 48px;
    }

    .create_qr_code_form_group_control_prefix {
        width: 48px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        background-color: #E6E8EB;
    }

    .create_qr_code_form_group_control_prefix img {
        width: 24px;
        height: 24px;
    }

    .create_qr_code_form_group_control_input {
        display: flex;
        align-items: center;
        padding-left: 16px;
        flex-grow: 1;
    }

    .create_qr_code_form_group_control_input input {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
        background-color: transparent;
        outline: none;
        border: 0px;
    }

    .create_qr_code_form_group_control_suffix {
        width: 44px;
        height: 100%;
        border-left: 1px solid #DCDCDF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }

    .create_qr_code_form_group_control_suffix img {
        width: 16px;
        height: 16px;
    }

    .create_qr_code_form_group:nth-child(3) {
        margin-bottom: 12px;
    }

    .create_qr_code_form_group:nth-child(4) {
        margin-bottom: 20px;
    }

    .create_qr_code_form_group:nth-child(5) {
        margin-bottom: 54px;
    }

    .create_qr_code_success_form {
        width: 448px;
        height: fit-content;
        background: #FFFFFF;
        border-radius: 24px;
        padding: 49px 40px 31px 40px;
        position: relative;
    }

    .create_qr_code_success_form_close_btn {
        position: absolute;
        top: 41px;
        right: 30px;
        width: 32px;
        height: 32px;
        border: 0px;
        outline: none;
        background-color: transparent;
    }

    .create_qr_code_success_form_close_btn img {
        width: 32px;
        height: 32px;
    }

    .create_qr_code_success_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 16px;
    }

    .create_qr_code_success_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 49px;
    }

    .create_qr_code_success_form_image {
        width: 190px;
        height: 190px;
        margin: 0px auto 54px auto;
    }

    .create_qr_code_success_form_image_wrapper {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .create_qr_code {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #83868D;
    }

    .create_qr_code_logo {
        position: absolute;
        top: 2.569vw;
        left: 2.986vw;
        display: flex;
        flex-direction: column;
        gap: 6.82px;
    }

    .create_qr_code_logo img {
        width: 9.375vw;
        height: 2.493vw;
    }

    .create_qr_code_logo span {
        font-weight: 400;
        font-size: 1.64vw;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #DCDCDF;
    }

    .create_qr_code_form {
        width: 31.111vw;
        height: fit-content;
        border-radius: 1.666vw;
        background-color: white;
        padding: 2.013vw 2.083vw 2.916vw 2.638vw;
    }

    .create_qr_code_form_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_header_icon {
        width: 2.569vw;
        height: 2.569vw;
    }

    .create_qr_code_form_close_btn {
        width: 2.222vw;
        height: 2.222vw;
        border: 0px;
        outline: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .create_qr_code_form_close_btn img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .create_qr_code_form_body {
        padding-left: 0.208vw;
        padding-top: 1.25vw;
        padding-right: 0.694vw;
    }

    .create_qr_code_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 0.694vw;
    }

    .create_qr_code_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 1.458vw;
    }

    .create_qr_code_form_group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.277vw;
    }

    .create_qr_code_form_group_label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_group_label span {
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #191D2C;
    }

    .create_qr_code_form_group_label_info {
        width: 1.111vw;
        height: 1.111vw;
    }

    .create_qr_code_form_group_control {
        width: 100%;
        display: flex;
        align-items: center;
        background: #F6F6F6;
        border: 1px solid #CACBCE;
        border-radius: 0.555vw;
        height: 3.333vw;
    }

    .create_qr_code_form_group_control_prefix {
        width: 3.333vw;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        background-color: #E6E8EB;
    }

    .create_qr_code_form_group_control_prefix img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .create_qr_code_form_group_control_input {
        display: flex;
        align-items: center;
        padding-left: 1.111vw;
        flex-grow: 1;
    }

    .create_qr_code_form_group_control_input input {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
        background-color: transparent;
        outline: none;
        border: 0px;
    }

    .create_qr_code_form_group_control_suffix {
        width: 3.055vw;
        height: 100%;
        border-left: 1px solid #DCDCDF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }

    .create_qr_code_form_group_control_suffix img {
        width: 1.111vw;
        height: 1.111vw;
    }

    .create_qr_code_form_group:nth-child(3) {
        margin-bottom: 0.833vw;
    }

    .create_qr_code_form_group:nth-child(4) {
        margin-bottom: 1.388vw;
    }

    .create_qr_code_form_group:nth-child(5) {
        margin-bottom: 3.75vw;
    }

    .create_qr_code_success_form {
        width: 31.111vw;
        height: fit-content;
        background: #FFFFFF;
        border-radius: 1.666vw;
        padding: 3.402vw 2.777vw 2.152vw 2.777vw;
        position: relative;
    }

    .create_qr_code_success_form_close_btn {
        position: absolute;
        top: 2.847vw;
        right: 2.083vw;
        width: 2.222vw;
        height: 2.222vw;
        border: 0px;
        outline: none;
        background-color: transparent;
    }

    .create_qr_code_success_form_close_btn img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .create_qr_code_success_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 1.111vw;
    }

    .create_qr_code_success_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 3.402vw;
    }

    .create_qr_code_success_form_image {
        width: 13.194vw;
        height: 13.194vw;
        margin: 0px auto 3.75vw auto;
    }

    .create_qr_code_success_form_image_wrapper {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .create_qr_code {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #83868D;
    }

    .create_qr_code_logo {
        position: absolute;
        top: 2.569vw;
        left: 2.986vw;
        display: flex;
        flex-direction: column;
        gap: 6.82px;
    }

    .create_qr_code_logo img {
        width: 9.375vw;
        height: 2.493vw;
    }

    .create_qr_code_logo span {
        font-weight: 400;
        font-size: 1.64vw;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #DCDCDF;
    }

    .create_qr_code_form {
        width: 31.111vw;
        height: fit-content;
        border-radius: 1.666vw;
        background-color: white;
        padding: 2.013vw 2.083vw 2.916vw 2.638vw;
    }

    .create_qr_code_form_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_header_icon {
        width: 2.569vw;
        height: 2.569vw;
    }

    .create_qr_code_form_close_btn {
        width: 2.222vw;
        height: 2.222vw;
        border: 0px;
        outline: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .create_qr_code_form_close_btn img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .create_qr_code_form_body {
        padding-left: 0.208vw;
        padding-top: 1.25vw;
        padding-right: 0.694vw;
    }

    .create_qr_code_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 0.694vw;
    }

    .create_qr_code_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 1.458vw;
    }

    .create_qr_code_form_group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.277vw;
    }

    .create_qr_code_form_group_label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_group_label span {
        font-weight: 400;
        font-size: 0.902vw;
        line-height: 150%;
        color: #191D2C;
    }

    .create_qr_code_form_group_label_info {
        width: 1.111vw;
        height: 1.111vw;
    }

    .create_qr_code_form_group_control {
        width: 100%;
        display: flex;
        align-items: center;
        background: #F6F6F6;
        border: 1px solid #CACBCE;
        border-radius: 0.555vw;
        height: 3.333vw;
    }

    .create_qr_code_form_group_control_prefix {
        width: 3.333vw;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        background-color: #E6E8EB;
    }

    .create_qr_code_form_group_control_prefix img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .create_qr_code_form_group_control_input {
        display: flex;
        align-items: center;
        padding-left: 1.111vw;
        flex-grow: 1;
    }

    .create_qr_code_form_group_control_input input {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #191D2C;
        background-color: transparent;
        outline: none;
        border: 0px;
    }

    .create_qr_code_form_group_control_suffix {
        width: 3.055vw;
        height: 100%;
        border-left: 1px solid #DCDCDF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }

    .create_qr_code_form_group_control_suffix img {
        width: 1.111vw;
        height: 1.111vw;
    }

    .create_qr_code_form_group:nth-child(3) {
        margin-bottom: 0.833vw;
    }

    .create_qr_code_form_group:nth-child(4) {
        margin-bottom: 1.388vw;
    }

    .create_qr_code_form_group:nth-child(5) {
        margin-bottom: 3.75vw;
    }

    .create_qr_code_success_form {
        width: 31.111vw;
        height: fit-content;
        background: #FFFFFF;
        border-radius: 1.666vw;
        padding: 3.402vw 2.777vw 2.152vw 2.777vw;
        position: relative;
    }

    .create_qr_code_success_form_close_btn {
        position: absolute;
        top: 2.847vw;
        right: 2.083vw;
        width: 2.222vw;
        height: 2.222vw;
        border: 0px;
        outline: none;
        background-color: transparent;
    }

    .create_qr_code_success_form_close_btn img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .create_qr_code_success_form_title {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 1.111vw;
    }

    .create_qr_code_success_form_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 3.402vw;
    }

    .create_qr_code_success_form_image {
        width: 13.194vw;
        height: 13.194vw;
        margin: 0px auto 3.75vw auto;
    }

    .create_qr_code_success_form_image_wrapper {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 1441px) {
    .create_qr_code {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #83868D;
    }

    .create_qr_code_logo {
        position: absolute;
        top: 37px;
        left: 43px;
        display: flex;
        flex-direction: column;
        gap: 6.82px;
    }

    .create_qr_code_logo img {
        width: 135px;
        height: 35.9px;
    }

    .create_qr_code_logo span {
        font-weight: 400;
        font-size: 23.625px;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #DCDCDF;
    }

    .create_qr_code_form {
        width: 448px;
        height: fit-content;
        border-radius: 24px;
        background-color: white;
        padding: 29px 30px 42px 38px;
    }

    .create_qr_code_form_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_header_icon {
        width: 37px;
        height: 37px;
    }

    .create_qr_code_form_close_btn {
        width: 32px;
        height: 32px;
        border: 0px;
        outline: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .create_qr_code_form_close_btn img {
        width: 32px;
        height: 32px;
    }

    .create_qr_code_form_body {
        padding-left: 3px;
        padding-top: 18px;
        padding-right: 10px;
    }

    .create_qr_code_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 10px;
    }

    .create_qr_code_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 21px;
    }

    .create_qr_code_form_group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .create_qr_code_form_group_label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .create_qr_code_form_group_label span {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #191D2C;
    }

    .create_qr_code_form_group_label_info {
        width: 16px;
        height: 16px;
    }

    .create_qr_code_form_group_control {
        width: 100%;
        display: flex;
        align-items: center;
        background: #F6F6F6;
        border: 1px solid #CACBCE;
        border-radius: 8px;
        height: 48px;
    }

    .create_qr_code_form_group_control_prefix {
        width: 48px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        background-color: #E6E8EB;
    }

    .create_qr_code_form_group_control_prefix img {
        width: 24px;
        height: 24px;
    }

    .create_qr_code_form_group_control_input {
        display: flex;
        align-items: center;
        padding-left: 16px;
        flex-grow: 1;
    }

    .create_qr_code_form_group_control_input input {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #191D2C;
        background-color: transparent;
        outline: none;
        border: 0px;
    }

    .create_qr_code_form_group_control_suffix {
        width: 44px;
        height: 100%;
        border-left: 1px solid #DCDCDF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }

    .create_qr_code_form_group_control_suffix img {
        width: 16px;
        height: 16px;
    }

    .create_qr_code_form_group:nth-child(3) {
        margin-bottom: 12px;
    }

    .create_qr_code_form_group:nth-child(4) {
        margin-bottom: 20px;
    }

    .create_qr_code_form_group:nth-child(5) {
        margin-bottom: 54px;
    }

    .create_qr_code_success_form {
        width: 448px;
        height: fit-content;
        background: #FFFFFF;
        border-radius: 24px;
        padding: 49px 40px 31px 40px;
        position: relative;
    }

    .create_qr_code_success_form_close_btn {
        position: absolute;
        top: 41px;
        right: 30px;
        width: 32px;
        height: 32px;
        border: 0px;
        outline: none;
        background-color: transparent;
    }

    .create_qr_code_success_form_close_btn img {
        width: 32px;
        height: 32px;
    }

    .create_qr_code_success_form_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -1px;
        color: #191D2C;
        margin-bottom: 16px;
    }

    .create_qr_code_success_form_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #83868D;
        margin-bottom: 49px;
    }

    .create_qr_code_success_form_image {
        width: 190px;
        height: 190px;
        margin: 0px auto 54px auto;
    }

    .create_qr_code_success_form_image_wrapper {
        display: flex;
        justify-content: center;
    }
}