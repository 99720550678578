@font-face {
  font-family: 'Azo Sans';
  src: url('./assets/fonts/AzoSans-BoldItalic.eot');
  src: local('Azo Sans Bold Italic'), local('AzoSans-BoldItalic'),
    url('./assets/fonts/AzoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-BoldItalic.woff') format('woff'),
    url('./assets/fonts/AzoSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Azo Sans Tn';
  src: url('./assets/fonts/AzoSans-Thin.eot');
  src: local('Azo Sans Thin'), local('AzoSans-Thin'),
    url('./assets/fonts/AzoSans-Thin.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-Thin.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-Thin.woff') format('woff'),
    url('./assets/fonts/AzoSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans Lt';
  src: url('./assets/fonts/AzoSans-LightItalic.eot');
  src: local('Azo Sans Light Italic'), local('AzoSans-LightItalic'),
    url('./assets/fonts/AzoSans-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-LightItalic.woff') format('woff'),
    url('./assets/fonts/AzoSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Azo Sans Tn';
  src: url('./assets/fonts/AzoSans-ThinItalic.eot');
  src: local('Azo Sans Thin Italic'), local('AzoSans-ThinItalic'),
    url('./assets/fonts/AzoSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-ThinItalic.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-ThinItalic.woff') format('woff'),
    url('./assets/fonts/AzoSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Azo Sans';
  src: url('./assets/fonts/AzoSans-Regular.eot');
  src: local('Azo Sans Regular'), local('AzoSans-Regular'),
    url('./assets/fonts/AzoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-Regular.woff') format('woff'),
    url('./assets/fonts/AzoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans Md';
  src: url('./assets/fonts/AzoSans-MediumItalic.eot');
  src: local('Azo Sans Medium Italic'), local('AzoSans-MediumItalic'),
    url('./assets/fonts/AzoSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-MediumItalic.woff') format('woff'),
    url('./assets/fonts/AzoSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Azo Sans Bk';
  src: url('./assets/fonts/AzoSans-Black.eot');
  src: local('Azo Sans Black'), local('AzoSans-Black'),
    url('./assets/fonts/AzoSans-Black.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-Black.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-Black.woff') format('woff'),
    url('./assets/fonts/AzoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans Md';
  src: url('./assets/fonts/AzoSans-Medium.eot');
  src: local('Azo Sans Medium'), local('AzoSans-Medium'),
    url('./assets/fonts/AzoSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-Medium.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-Medium.woff') format('woff'),
    url('./assets/fonts/AzoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans Bk';
  src: url('./assets/fonts/AzoSans-BlackItalic.eot');
  src: local('Azo Sans Black Italic'), local('AzoSans-BlackItalic'),
    url('./assets/fonts/AzoSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-BlackItalic.woff') format('woff'),
    url('./assets/fonts/AzoSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Azo Sans Lt';
  src: url('./assets/fonts/AzoSans-Light.eot');
  src: local('Azo Sans Light'), local('AzoSans-Light'),
    url('./assets/fonts/AzoSans-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-Light.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-Light.woff') format('woff'),
    url('./assets/fonts/AzoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans';
  src: url('./assets/fonts/AzoSans-Italic.eot');
  src: local('Azo Sans Italic'), local('AzoSans-Italic'),
    url('./assets/fonts/AzoSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-Italic.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-Italic.woff') format('woff'),
    url('./assets/fonts/AzoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Azo Sans';
  src: url('./assets/fonts/AzoSans-Bold.eot');
  src: local('Azo Sans Bold'), local('AzoSans-Bold'),
    url('./assets/fonts/AzoSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AzoSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/AzoSans-Bold.woff') format('woff'),
    url('./assets/fonts/AzoSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std Bl';
  src: url('./assets/fonts/CircularStd-Black.otf');
  src: local('Circular Std Black'), local('CircularStd-Black'),
    url('./assets/fonts/CircularStd-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std Bl';
  src: url('./assets/fonts/CircularStd-BlackItalic.otf');
  src: local('Circular Std Black Italic'), local('CircularStd-BlackItalic'),
    url('./assets/fonts/CircularStd-BlackItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Circular Std';
  src: url('./assets/fonts/CircularStd-Bold.otf');
  src: local('Circular Std Bold'), local('CircularStd-Bold'),
    url('./assets/fonts/CircularStd-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std';
  src: url('./assets/fonts/CircularStd-BoldItalic.otf');
  src: local('Circular Std Bold Italic'), local('CircularStd-BoldItalic'),
    url('./assets/fonts/CircularStd-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Circular Std Bk';
  src: url('./assets/fonts/CircularStd-Book.otf');
  src: local('Circular Std Book'), local('CircularStd-Book'),
    url('./assets/fonts/CircularStd-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std Bk';
  src: url('./assets/fonts/CircularStd-BookItalic.otf');
  src: local('Circular Std Book Italic'), local('CircularStd-BookItalic'),
    url('./assets/fonts/CircularStd-BookItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Circular Std Md';
  src: url('./assets/fonts/CircularStd-Medium.otf');
  src: local('Circular Std Medium'), local('CircularStd-Medium'),
    url('./assets/fonts/CircularStd-Medium.otf') format('opentype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std Md';
  src: url('./assets/fonts/CircularStd-MediumItalic.otf');
  src: local('Circular Std Medium Italic'), local('CircularStd-MediumItalic'),
    url('./assets/fonts/CircularStd-MediumItalic.otf') format('opentype');
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-MediumItalic.woff") format("woff");
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url("./assets/fonts/Gilroy-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy Black';
  src: url("./assets/fonts/Gilroy-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Black';
  src: url("./assets/fonts/Gilroy-BlackItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy Heavy';
  src: url("./assets/fonts/Gilroy-Heavy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Heavy';
  src: url("./assets/fonts/Gilroy-HeavyItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #CACBCE;
}

::-webkit-scrollbar-track {
  background: transparent;
}

button {
  cursor: pointer;
}