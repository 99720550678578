@media only screen and (max-width: 480px) {
    .dashboard_layout {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        display: flex;
        background-color: #F6F6F6;
    }

    .dashboard_layout_sidebar {
        width: 236px;
        height: 100vh;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: #191D2C;
        padding: 37px 27px 16px 25px;
    }

    .dashboard_layout_sidebar_logo {
        margin-left: 3px;
        width: 135px;
        height: 35.9px;
        margin-bottom: 2.82px;
    }

    .dashboard_layout_first_line_text {
        font-weight: 400;
        font-size: 23.625px;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #A7A8AE;
        margin-bottom: 51.28px;
    }

    .dashbaord_layout_menus {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .dashboard_layout_menu {
        height: 42px;
        display: flex;
        align-items: center;
        gap: 13px;
    }

    .dashboard_layout_menu_icon {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .dashboard_layout_menu_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #8F9098;
    }

    .dashboard_layout_menu.active {
        padding: 11.5px 15px;
        background: #494A56;
        border-radius: 6px;
    }

    .dashboard_layout_menu.active .dashboard_layout_menu_text {
        color: white;
    }

    .dashboard_layout_body {
        max-height: 100vh;
        overflow-y: auto;
        padding: 56px 24px 22px 24px;
        flex-grow: 1;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .dashboard_layout {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        display: flex;
        background-color: #F6F6F6;
    }

    .dashboard_layout_sidebar {
        width: 16.388vw;
        height: 100vh;
        border-top-right-radius: 1.388vw;
        border-bottom-right-radius: 1.388vw;
        background-color: #191D2C;
        padding: 2.569vw 1.875vw 1.111vw 1.736vw;
    }

    .dashboard_layout_sidebar_logo {
        margin-left: 0.208vw;
        width: 9.375vw;
        height: 2.493vw;
        margin-bottom: 0.195vw;
    }

    .dashboard_layout_first_line_text {
        font-weight: 400;
        font-size: 1.64vw;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #A7A8AE;
        margin-bottom: 3.561vw;
    }

    .dashbaord_layout_menus {
        display: flex;
        flex-direction: column;
        gap: 0.347vw;
    }

    .dashboard_layout_menu {
        height: 2.916vw;
        display: flex;
        align-items: center;
        gap: 0.902vw;
    }

    .dashboard_layout_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .dashboard_layout_menu_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #8F9098;
    }

    .dashboard_layout_menu.active {
        padding: 0.798vw 1.041vw;
        background: #494A56;
        border-radius: 0.416vw;
    }

    .dashboard_layout_menu.active .dashboard_layout_menu_text {
        color: white;
    }

    .dashboard_layout_body {
        max-height: 100vh;
        overflow-y: auto;
        padding: 3.888vw 1.666vw 1.527vw 1.666vw;
        flex-grow: 1;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .dashboard_layout {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        display: flex;
        background-color: #F6F6F6;
    }

    .dashboard_layout_sidebar {
        width: 16.388vw;
        height: 100vh;
        border-top-right-radius: 1.388vw;
        border-bottom-right-radius: 1.388vw;
        background-color: #191D2C;
        padding: 2.569vw 1.875vw 1.111vw 1.736vw;
    }

    .dashboard_layout_sidebar_logo {
        margin-left: 0.208vw;
        width: 9.375vw;
        height: 2.493vw;
        margin-bottom: 0.195vw;
    }

    .dashboard_layout_first_line_text {
        font-weight: 400;
        font-size: 1.64vw;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #A7A8AE;
        margin-bottom: 3.561vw;
    }

    .dashbaord_layout_menus {
        display: flex;
        flex-direction: column;
        gap: 0.347vw;
    }

    .dashboard_layout_menu {
        height: 2.916vw;
        display: flex;
        align-items: center;
        gap: 0.902vw;
    }

    .dashboard_layout_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
        flex: none;
    }

    .dashboard_layout_menu_text {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #8F9098;
    }

    .dashboard_layout_menu.active {
        padding: 0.798vw 1.041vw;
        background: #494A56;
        border-radius: 0.416vw;
    }

    .dashboard_layout_menu.active .dashboard_layout_menu_text {
        color: white;
    }

    .dashboard_layout_body {
        max-height: 100vh;
        overflow-y: auto;
        padding: 3.888vw 1.666vw 1.527vw 1.666vw;
        flex-grow: 1;
    }
}

@media only screen and (min-width: 1441px) {
    .dashboard_layout {
        font-family: 'Circular Std', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        display: flex;
        background-color: #F6F6F6;
    }

    .dashboard_layout_sidebar {
        width: 236px;
        height: 100vh;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: #191D2C;
        padding: 37px 27px 16px 25px;
    }

    .dashboard_layout_sidebar_logo {
        margin-left: 3px;
        width: 135px;
        height: 35.9px;
        margin-bottom: 2.82px;
    }

    .dashboard_layout_first_line_text {
        font-weight: 400;
        font-size: 23.625px;
        line-height: 110%;
        letter-spacing: -0.84375px;
        color: #A7A8AE;
        margin-bottom: 51.28px;
    }

    .dashbaord_layout_menus {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .dashboard_layout_menu {
        height: 42px;
        display: flex;
        align-items: center;
        gap: 13px;
    }

    .dashboard_layout_menu_icon {
        width: 24px;
        height: 24px;
        flex: none;
    }

    .dashboard_layout_menu_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #8F9098;
    }

    .dashboard_layout_menu.active {
        padding: 11.5px 15px;
        background: #494A56;
        border-radius: 6px;
    }

    .dashboard_layout_menu.active .dashboard_layout_menu_text {
        color: white;
    }

    .dashboard_layout_body {
        max-height: 100vh;
        overflow-y: auto;
        padding: 56px 24px 22px 24px;
        flex-grow: 1;
    }
}